import { getCookie } from '@soundtrackyourbrand/tracking.js'
// Remove this file after storefront's domain migration
import { handleDomainMigration } from './domain-migrator'
;(function () {
  if (isGoogleBot()) {
    // If we see that Googlebot doesn't like our redirects we should try to return here
    // return
  }
  // Ensure UTM params end up in business
  addUTMParamsToBusinessLinks()

  const isProdEnv = process.env.NODE_ENV === 'production'

  // We don't do tracking here as I'm not sure how to deal with tracking consent and if there are
  // side effects of instantiating the tracking lib twice.
  const createTrackingEvent = isProdEnv ? () => {} : console.log

  // These settings are useful to uncomment when testing
  handleDomainMigration({
    // Don't automatically redirect from source to destination during testing
    requireTrigger: false,
    // Also allow various local domains
    allowedDestinations:
      /^https?:\/\/(www-staging\.soundtrack\.io|(?:[^.]+\.)?storefront-next\.pages\.dev|localhost:300\d)$/,
    // The domain that cookies are copied FROM
    source: 'https://www-staging.soundtrackyourbrand.com',
    // source: 'https://storefront-source.storefront-next.pages.dev',
    // source: 'http://localhost:3000',
    // The domain that cookies are copied TO
    destination: 'https://www-staging.soundtrack.io',
    // destination: 'https://storefront-destination.storefront-next.pages.dev',
    // destination: 'http://localhost:3001',
    // While testing, it is nice to always overwrite the state
    // skipIfLocalStoragePopulated: false,
  })
    .then((result) => {
      switch (result.outcome) {
        case 'redirecting-to-source':
        case 'redirecting-to-destination':
          // Prevent the rest of the page from loading while the redirect happens
          window.stop()

          createTrackingEvent('Domain Migration - Redirect', {
            'Migration Outcome': result.outcome,
            Destination: result.url.toString(),
          })
          window.location.href = result.url.toString()
          return
        case 'success':
          createTrackingEvent('Domain Migration - Success', {
            'Migration Outcome': result.outcome,
            'Migrated Cookies': result.cookies
              ? Object.keys(result.cookies)
              : undefined,
            'Migrated Storage': result.localStorage
              ? Object.keys(result.localStorage)
              : undefined,
          })
          return
        default:
          createTrackingEvent('Domain Migration - Skipped', {
            'Migration Outcome': result.outcome,
          })
      }
    })
    .catch((error) => {
      createTrackingEvent('Domain Migration - Error', {
        Error: (error && error.message) || 'unknown',
      })
    })
})()

// While storefront and business reside on different domains, during the migration, we need to
// explicitly add UTM params to all business links as these are otherwise overwritten with default
// values.
function addUTMParamsToBusinessLinks() {
  document.addEventListener('click', function (event) {
    const target = event.target
    if (!(target instanceof Element)) {
      return
    }
    // Bail unless clicked element is an anchor tag (<a>) or an element within an anchor tag
    const closestLink = getClosestLink(target)
    if (!closestLink) {
      return
    }
    const href = closestLink.getAttribute('href')
    if (!href || !isBusiness(href)) {
      return
    }
    try {
      const url = urlWithUtmParams(
        href,
        new URLSearchParams(window.location.search),
      )
      closestLink.setAttribute('href', url.toString())
    } catch (error) {
      // Handle invalid URLs
      console.error('Invalid URL:', href, error)
    }
  })
}

function getClosestLink(target: Element): Element | undefined {
  return (
    (target &&
      'closest' in target &&
      typeof target.closest === 'function' &&
      target.closest('A')) ||
    undefined
  )
}

// Returns a URL where UTM params, read from the query string or cookies, have been appended as query parameters
export function urlWithUtmParams(
  href: string,
  currentSearchParams: URLSearchParams,
): URL {
  const url = new URL(href)
  const utmPresentInQs = !!currentSearchParams.get('utm_source')
  for (const utmParam of ['source', 'medium', 'campaign', 'content']) {
    const paramValue = utmPresentInQs
      ? currentSearchParams.get('utm_' + utmParam)
      : getCookie('syb.' + utmParam)
    if (paramValue) {
      url.searchParams.set('utm_' + utmParam, paramValue)
    }
  }
  return url
}

function isBusiness(href: string) {
  return href.match(
    /^https:\/\/(business|app)(-staging)?\.(soundtrackyourbrand\.com|soundtrack\.io)/,
  )
}

function isGoogleBot() {
  return /googlebot/i.test(navigator.userAgent)
}
